<template>
  <!-- accApplyPgmMngList가 조회된 경우에만 (undefined가 아닌 경우에만) -->
  <router-view
    v-if="accApplyPgmMngList"
    :accApplyPgmMngList="accApplyPgmMngList"
  />
</template>

<script>
/**
 * Programs > 공모 지원하기
 */
export default {
  name: 'ProgramsApplication',

  props: {
    /**
     * Acc지원프로그램 관리 목록
     * @type {import('./dto/AccApplyPgmMngDetailDto').default[]}
     */
    accApplyPgmMngList: {
      type: Array,
    },
  },
};
</script>
